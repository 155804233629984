import {
  AppWrapper,
  IconUserAccount,
  IconLogout,
  IconCube,
  Show,
  SupportBox,
} from '@perts/ui';
import { PagesMenu } from './PagesMenu';
import { BreadcrumbsNavigation } from './BreadcrumbsNavigation';
import { toHome, toHomeNoProgram } from 'pages';
import {
  ProgramGuide,
  TopMenuButtonImpersonate,
  TopMenu,
  TopMenuButton,
} from 'components';
import {
  Program,
  useCurrentUserQuery,
  useProgramGetByParams,
  User,
} from 'models';
import { usePermissions } from 'utils';
import { Redirect, useRouteMatch } from 'react-router-dom';

type MenuFooterProps = { program: Program | undefined; user: User | undefined };
const MenuFooter = ({ program, user }: MenuFooterProps) => (
  <>
    {program && user && <ProgramGuide program={program} user={user} />}
    <SupportBox />
  </>
);

export const PagesTemplate = ({ children }) => {
  let menuTop = <></>;

  const { data: program, isLoading: isLoadingProgram } =
    useProgramGetByParams();
  const { data: userAuthenticated, isLoading: isLoadingUser } =
    useCurrentUserQuery();

  const { isAdmin } = usePermissions();

  const routeMatchHome = useRouteMatch('/home/:programId');

  if (
    !routeMatchHome &&
    !isLoadingProgram &&
    !isLoadingUser &&
    program &&
    !program.active &&
    !isAdmin
  ) {
    return <Redirect to={toHome(program.uid)} />;
  }

  if (userAuthenticated) {
    menuTop = (
      <TopMenu>
        <TopMenuButtonImpersonate />

        <Show when={isAdmin}>
          <TopMenuButton to="/canvas" color="text">
            <IconCube />
          </TopMenuButton>
        </Show>

        <TopMenuButton
          to={`/users/${userAuthenticated.short_uid}`}
          color="text"
        >
          <IconUserAccount />
        </TopMenuButton>

        <TopMenuButton to="/logout?logout_canvas=true" color="text">
          <IconLogout />
        </TopMenuButton>
      </TopMenu>
    );
  }

  return (
    <AppWrapper
      menu={<PagesMenu />}
      menuFooter={<MenuFooter program={program} user={userAuthenticated} />}
      menuTop={<>{menuTop}</>}
      breadcrumbsNavigation={<BreadcrumbsNavigation />}
      program={program}
      toHome={program ? toHome(program.label) : toHomeNoProgram()}
    >
      {children}
    </AppWrapper>
  );
};
