import { useQuery } from 'react-query';

import { OptimizerConditionData } from './types';
import { get } from 'services/triton/optimizer';

// -----------------------------------------------------------------------------
//   queryKey Generators
// -----------------------------------------------------------------------------

export const queryKeyOptimizerCondition = (
  variable: string,
  slot: string,
  userId: string,
) => ['variable', variable, 'slot', slot, 'user', userId];

// -----------------------------------------------------------------------------
//   API Hooks
// -----------------------------------------------------------------------------

export const useOptimizerCondition = (
  variable: string,
  slot: string,
  userId: string,
) =>
  useQuery<OptimizerConditionData, Error>(
    // queryKey
    queryKeyOptimizerCondition(variable, slot, userId),
    // queryFn
    () => get(variable, slot, userId),
    {
      enabled: Boolean(userId),
    },
  );
