import type { TeamResults } from '@perts/model';

import { getRecentCycleValue } from 'utils';

// The Research team specified that metric labels be replaced by a number in
// the survey redirect URL, corresponding to this order, counting from 1.
export const labelOrder = [
  'affirming-identities',
  'classroom-community',
  'feedback-for-growth',
  'learning-goals',
  'meaningful-work',
  'student-voice',
  'supportive-teaching',
  'teacher-caring',
  'well-organized-class',
  'autonomy-over-process',
  'collaborative-learning',
  'learning-from-misconceptions',
  'math-identity',
  'relevance-of-math',
];

// Find the learning condition the user is most likely to want to improve
// (with the lowest recent score), based on their report data.
export const getLearningConditionId = (
  results: TeamResults,
): number | undefined => {
  // Look only at composite scores of each learning condition, without
  // disaggregating to any subgroups.
  const recentPositiveByMetric = Object.entries(results.experience).map(
    ([metricLabel, experienceResults]) => ({
      metricLabel,
      ratedPositive: getRecentCycleValue(
        experienceResults.composite.all_participants,
      ),
    }),
  );

  // Reduce to metric with the lowest score.
  const { metricLabel: leastPositiveMetric } = recentPositiveByMetric.reduce(
    (prev, curr) =>
      curr.ratedPositive !== null &&
      prev.ratedPositive !== null &&
      curr.ratedPositive < prev.ratedPositive
        ? curr
        : prev,
    { metricLabel: 'unknown', ratedPositive: Infinity },
  );

  const index = labelOrder.indexOf(leastPositiveMetric);

  return index === -1 ? undefined : index + 1;
};
