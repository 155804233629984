import { MouseEvent } from 'react';
import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components/macro';

import { isExternal } from '../shared';
import { theme } from '../../';

export type MenuItemIconStyledProps = {
  iconLetter?: string;
};

export type MenuItemProps = MenuItemIconStyledProps & {
  children: React.ReactNode;
  icon: React.ReactNode;

  // Props provided to NavLink
  // https://reactrouter.com/web/api/NavLink

  // A string representation of the Link location, created by concatenating the
  // location’s pathname, search, and hash properties.
  to: string;

  // When true, the active class/style will only be applied if the location is
  // matched exactly.
  exact?: boolean;

  // When true, the trailing slash on a location’s pathname will be taken into
  // consideration when determining if the location matches the current URL.
  // See the <Route strict> documentation for more information.
  strict?: boolean;

  // https://v5.reactrouter.com/web/api/NavLink/isactive-func
  isActive?: (match: any, location: any) => boolean;

  // When true, the NavLink navigation will be disabled
  disabled?: boolean;
};

export const getLinkStyled = (component: any) => styled(component)`
  display: flex;
  flex-direction: row;
  align-items: center;

  margin: ${theme.units.paddingSm} 0;
  padding: 10px;

  @media (max-width: ${theme.units.menuCollapseWidth}px) {
    margin: 2px;
    padding: 8px 8px 8px 6px;
  }

  border: 2px solid transparent;
  border-radius: ${theme.units.borderRadius};

  color: ${theme.colors.offWhite};
  font-weight: 600;
  text-decoration: none;

  &:hover,
  &.active {
    color: ${theme.colors.offWhite};
    border: 2px solid ${theme.colors.primary};
  }

  &:focus {
    outline: 0;
    box-shadow: 0 0 0 4px ${theme.colors.primaryLighten1};
    border-radius: 3px;
  }

  @media (max-width: ${theme.units.menuCollapseWidth}px) {
    &:focus {
      box-shadow: 0 0 0 2px ${theme.colors.primaryLighten1};
    }
  }

  & > :not(:first-child) {
    margin-left: 12px;
  }

  &[disabled] {
    color: ${theme.colors.offWhite};
    cursor: not-allowed;
    background: unset;
  }
`;

export const MenuItemIconStyled = styled.div<MenuItemIconStyledProps>`
  position: relative;
  width: ${theme.units.menuIconSize};
  height: ${theme.units.menuIconSize};
  > svg {
    width: ${theme.units.menuIconSize};
    height: ${theme.units.menuIconSize};
  }

  ${(props) =>
    Boolean(props.iconLetter) &&
    css`
      :after {
        content: '${props.iconLetter}';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: ${theme.units.menuIconSize};
        height: ${theme.units.menuIconSize};
        display: none;
        font-size: ${theme.units.menuIconSize};
        @media (max-width: ${theme.units.menuCollapseWidth}px) {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      > svg {
        @media (max-width: ${theme.units.menuCollapseWidth}px) {
          opacity: 0;
        }
      }
    `};
`;

export const MenuItemTextStyled = styled.div`
  display: flex;
  align-items: center;

  > :not(:first-child) {
    margin-left: 10px;
  }

  @media (max-width: ${theme.units.menuCollapseWidth}px) {
    display: none;
  }
`;

const MenuItemStyled = styled.div``;
const ExternalLinkStyled = getLinkStyled(styled.a``);
const NavLinkStyled = getLinkStyled(NavLink);

export const MenuItem = ({
  icon,
  iconLetter,
  children,
  disabled,
  ...navLinkProps
}: MenuItemProps) => {
  // Handle to disabled navigation action from NavLink
  const handleClick = (e: MouseEvent) => {
    e.preventDefault();
  };

  const isAnchor = isExternal(navLinkProps.to);

  const updatedNavLinkProps = {
    ...navLinkProps,
    ...(disabled ? { onClick: handleClick } : {}),
    disabled,
    ...(isAnchor ? { href: navLinkProps.to } : {}),
  };

  const Link = isAnchor ? ExternalLinkStyled : NavLinkStyled;

  return (
    <MenuItemStyled>
      <Link {...updatedNavLinkProps}>
        <MenuItemIconStyled iconLetter={iconLetter}>{icon}</MenuItemIconStyled>{' '}
        <MenuItemTextStyled>{children}</MenuItemTextStyled>
      </Link>
    </MenuItemStyled>
  );
};
