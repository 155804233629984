import uri from 'urijs';

import { OPTIMIZER_URL_PREFIX } from 'services/triton/config';

// Get data from a response from the optimizer cloud function.
// Note this never uses the `/redirect` option in the path because it's only
// intended to be used as a data API.
export const optimizerUrl = (variable: string, slot: string, userId: string) =>
  uri(`${OPTIMIZER_URL_PREFIX}/vars/${variable}/slots/${slot}`)
    .setSearch({ user: userId })
    .toString();
