import type { PromptExports } from './types';

import DefaultComponent from './DefaultComponent';
import PromptAscend23Demographics from './PromptAscend23Demographics';
import PromptElevate23Demographics from './PromptElevate23Demographics';
import PromptElevate23GroupReports from './PromptElevate23GroupReports';
import PromptElevate24ResourceDelivery from './PromptElevate24ResourceDelivery';
import PromptDemoByOrganization from './PromptDemoByOrganization';
import PromptTou from './PromptTou';

const promptComponents: { [promptName: string]: PromptExports } = {
  default: DefaultComponent,
  PromptAscend23Demographics,
  PromptElevate23Demographics,
  PromptElevate23GroupReports,
  PromptElevate24ResourceDelivery,

  PromptDemoByOrganization,
  PromptTou,
};

const capitalizeFirstLetter = (str: string) =>
  str.charAt(0).toUpperCase() + str.slice(1);

// Prompts may export properties described by the PromptExports type.
// If any of the optional ones are missing, they are given defaults here.
// This ensures that prompts without a useShouldDisplay hook are displayed.
// Note that the DefaultComponent defines a useShouldDisplay hook that always
// returns false. This ensures that prompts in the database that nevertheless
// lack a render component have no effect in the client.
export const promptComponent = (
  promptLabel: string,
): Required<PromptExports> => {
  const componentKey = `Prompt${capitalizeFirstLetter(promptLabel)}`;

  if (!(componentKey in promptComponents)) {
    console.error('Render component for prompt not found:', componentKey);
  }

  const componentExports =
    promptComponents[componentKey] || promptComponents.default;

  return {
    useShouldDisplay: () => true,
    ...componentExports,
  };
};
