import styled from 'styled-components/macro';

import { programs } from '@perts/config';
import { IconGuide, Menu, theme } from '@perts/ui';

import { canvasLink } from 'config';
import { Program, User } from 'models';

const GuideButtonStyled = styled(Menu.Item)`
  background-color: ${theme.colors.primary};
  padding: 12px;
  justify-content: center;

  @media (max-width: ${theme.units.menuCollapseWidth}px) {
    padding: 8px 0;
  }
`;

type Props = {
  program: Program;
  user: User;
};

export const ProgramGuide = ({ program, user }: Props) => {
  // If program is not configured to have a guide course in Canvas, then don't
  // display a nav link.
  const programConf = programs.find((p) => p.uid === program.uid);
  if (!programConf || !programConf.canvasGuide) {
    return null;
  }

  // If user has no managed organizations/groups/communities, then don't display
  // a nav link.
  // NOTE: this simplistic rule, where ANY managed organization triggers the
  // link, may not always be the desired behavior. It will render the link when
  // 1. The user has a managed org in a totally different program, e.g.
  // Catalyze. However we expect zero instances of this in the wild.
  // 2. The user has a managed org in older versions of this program, but not
  // this one. This may be a good thing since it usually means this is the
  // _kind of person_ who would be interested in the guide.
  if (user.managed_organizations.length === 0) {
    return null;
  }

  const to = `${canvasLink}/courses/${programConf.canvasGuide.courseId}`;

  return (
    <GuideButtonStyled
      aria-label={programConf.canvasGuide.name}
      icon={<IconGuide />}
      to={to}
    >
      {programConf.canvasGuide.name}
    </GuideButtonStyled>
  );
};
