import { isTeamData } from '@perts/model';
import { getMessageFromErrors } from '@perts/util';

import {
  useClassGetByParams,
  useDatasetTeam,
  useGroupGetByParams,
  useMetricsQuery,
  useProgramsQuery,
} from 'models';
import { getAvailableMetrics } from '../components';

// eslint-disable-next-line complexity
export const useClassReportData = () => {
  const classQuery = useClassGetByParams();

  const groupQuery = useGroupGetByParams();
  const metricsQuery = useMetricsQuery();
  const programsQuery = useProgramsQuery();

  // This has an `enabled` check that will trigger once the group and user ID
  // are loaded and available.
  const datasetQuery = useDatasetTeam(classQuery.data?.uid);

  const isSuccess =
    classQuery.isSuccess && metricsQuery.isSuccess && programsQuery.isSuccess;

  let errorMessage = isSuccess
    ? undefined
    : getMessageFromErrors([
        classQuery.error,
        metricsQuery.error,
        programsQuery.error,
      ]);

  const allMetrics = metricsQuery.data || [];
  const cls = classQuery.data;
  const dataset = datasetQuery.data;
  const group = groupQuery.data;
  const program = (programsQuery.data || []).find(
    (p) => p.uid === cls?.program_id,
  );

  if (!cls || !group || !program || !datasetQuery.isSuccess) {
    return undefined;
  }

  if (dataset && !isTeamData(dataset)) {
    errorMessage = `Unexpected dataset type: "${
      (dataset as any).dataset_type
    }"`;
  }

  const programMetricIds = program ? program.metrics.map(({ uid }) => uid) : [];
  const programMetrics = allMetrics.filter(({ uid }) =>
    programMetricIds.includes(uid),
  );

  const reportMetrics = dataset?.results
    ? getAvailableMetrics(dataset.results, allMetrics)
    : [];

  return {
    cls,
    group,
    lastRun: dataset?.last_run,
    lastUpdatedResults: dataset?.last_updated_results,
    program,
    programMetrics,
    reportMetrics,
    results: dataset?.results,
    errorMessage,
  };
};
