// See https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/digest#converting_a_digest_to_a_hex_string
export const sha256Digest = async (
  message: string,
): Promise<string | undefined> => {
  // Fail gracefully if the browser lacks support.
  if (typeof window?.crypto?.subtle?.digest !== 'function') {
    return undefined;
  }
  const msgUint8 = new TextEncoder().encode(message);
  const hashBuffer = await window.crypto.subtle.digest('SHA-256', msgUint8);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray
    .map((b) => b.toString(16).padStart(2, '0'))
    .join('');
  return hashHex;
};
