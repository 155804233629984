// Returns a mutation for updating Cycles. Uses optimistic updates.

import { useMutation, useQueryClient } from 'react-query';
import { message } from 'antd';

import { Cycle, queryKeyCyclesByGroup, updateCycle } from 'models';
import mutationUpdateFn from 'utils/mutationUpdateFn';

export const useCyclesUpdate = () => {
  const queryClient = useQueryClient();

  type MutationArgs = { cycles: Cycle[]; groupId: string };
  return useMutation<Cycle[], Error, MutationArgs, unknown>(
    ({ cycles, groupId }) =>
      mutationUpdateFn<Cycle>(
        queryClient,
        updateCycle,
        cycles,
        queryKeyCyclesByGroup(groupId),
      ),
    {
      onSuccess: () => {
        message.success(`Successfully updated cycles.`);
      },
      onError: () => {
        message.error(`Unable to update cycles.`);
      },
    },
  );
};
